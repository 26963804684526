import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Alert from 'components/common/Alert';
import Collapsible from 'components/common/Collapsible';
import styles from './CollapsibleAlert.module.scss';
const CollapsibleAlert = ({ icon, title, description, variant, className, children }) => {
    const { t } = useTranslation();
    return (_jsxs(Alert, { className: clsx(className, styles.alert), variant: variant, children: [_jsxs("div", { className: styles.header, children: [_jsx("span", { className: clsx(styles.icon), children: icon }), _jsx("h3", { children: title })] }), _jsx("p", { children: description }), _jsx(Collapsible, { collapsedToggle: _jsx("u", { className: styles.toggle, children: t('common.alerts.read-more') }), expandedToggle: _jsx("u", { className: styles.toggle, children: t('common.alerts.show-less') }), children: _jsx("div", { className: styles.children, children: children }) })] }));
};
export default CollapsibleAlert;
